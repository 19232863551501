@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.h-cashier {
  width: 380px;
  background: #fff;
  overflow: hidden;
  .head {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    border-bottom: 1px solid #dfe0e3;
    &-t {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
    }
    &-r {
      display: flex;
      align-items: center;
      .hx_btn {
        min-width: 106px;
        height: 34px;
        display: flex;
        align-items: center;
        background: #f2f3f6;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #19222e;
        cursor: pointer;
        & + .hx_btn {
          margin-left: 10px;
        }
        .hexiao {
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .c-main {
    height: calc(100% - 241px);
    overflow: hidden;
    overflow-y: auto;
    border-bottom: 1px solid @border-color-base;
  }
  .c-clear {
    height: 44px;
    background: rgba(242, 243, 246, 0.4);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #767b84;
    line-height: 44px;
    padding: 0 14px;
    border-bottom: 1px solid @border-color-base;
    svg {
      width: 14px;
      height: 14px;
    }
    .iclear {
      cursor: pointer;
    }
  }
  .c-sum {
    height: 54px;
    padding: 0 14px;
    background: rgba(242, 243, 246, 0.4);
    border-bottom: 1px solid @border-color-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .c-s-t {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .c-s-p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
    }
  }
  .c-settlement {
    display: flex;
    justify-content: flex-end;
    .c-s {
      cursor: pointer;
    }
    .c-reg {
      flex: 1;
      height: 58px;
      line-height: 58px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #86CE65;
      position: relative;
      .ant-btn {
        color: #86CE65;
      }
      &::after {
        content: '';
        width: 1px;
        height: 36px;
        background: #e5e7e6;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    .c-list {
      width: 140px;
      height: 58px;
      line-height: 58px;
      text-align: center;
      font-size: 16px;
    }
    .c-settl {
      flex: 1;
      height: 58px;
      text-align: center;
      background: #86CE65;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      border-radius: 0;
    }
  }
  .c-btns {
    .c-btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      .bord {
        width: 1px;
        height: 26px;
        background: #e5e7e6;
      }
      .c-b-col {
        &.ant-btn {
          height: 45px;
          flex: 1;
        }
      }
    }

    .c-price-info {
      height: 78px;
      background: #F9F9F9;
      padding: 0 18px;
      .c-p-i-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 38px;
        & + .c-p-i-col {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
        .text {
          font-size: 13px;
          font-weight: 400;
          color: #262626;
        }
      }
    }
    .c-balance {
      height: 54px;
      background: linear-gradient(135deg, #8CF2BA 0%, #78C7F5 52%, #4B9BFF 100%);
      padding: 0 18px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: 14px;
      .c-b-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .rmb {
        font-size: 18px;
      }
      .c-text {
      }
    }
    .ml {
      margin-left: 26px;
    }
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;