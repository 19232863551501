.ant-input-lg,
.ant-btn-lg {
  border-radius: 26px;
}
.ant-input-lg {
  font-size: 14px;
}
.ant-input-affix-wrapper > input.ant-input,
.ant-picker-input > input {
  padding: 0 2px;
}
.ant-btn-lg {
  font-size: 16px;
}
.ant-btn-sm {
  font-size: 12px;
}
.ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-radius: 0;
}
.ant-drawer-content {
  border-radius: 0;
  .ant-drawer-header {
    padding: 17px 15px;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid @border-color-base;
    .ant-drawer-close {
      right: initial;
      left: 0;
    }
  }
  .ant-drawer-body {
    background-color: @bg-color;
    padding: 0;
    overflow-x: hidden;
  }
  .ant-drawer-footer {
    box-shadow: 0px -4px 8px 0px rgba(25, 34, 46, 0.08);
    padding-right: 20px;
  }
}
.search-button {
  .ant-input-affix-wrapper {
    padding-right: 68px;
    height: 36px;
    &.ant-input-affix-wrapper-lg {
      height: 40px;
    }
  }
  .ant-input-group-addon {
    position: absolute;
    z-index: 19;
    left: initial !important;
    right: -1px;
    top: 0;
    width: 68px;
    background: transparent;
  }
  .ant-input-search-button {
    font-size: 14px;
    height: 34px;
    margin-top: 1px;
    &.ant-btn-lg {
      height: 38px;
    }
    &:after {
      display: none;
    }
  }
}
.search-text-button {
  .ant-input-affix-wrapper {
    padding-right: 60px;
  }
  .ant-input-group-addon {
    background-color: transparent;
    position: absolute;
    left: initial !important;
    width: 56px;
    right: 0;
    top: 0;
    z-index: 19;
    height: 100%;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 22px;
      background-color: @bg-color;
    }
  }
  .ant-input-search-button {
    background-color: transparent;
    height: 100%;
    border: none;
    color: @primary-color;
    box-shadow: none;
    width: 100%;
    padding: 0;
    font-size: 14px;
    &:after {
      display: none;
    }
  }
}
.ant-divider-horizontal {
  margin: 14px 0;
}
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}
.ant-tabs {
  .ant-tabs-tab {
    color: @text-color-secondary;
    .tab-item{
      color: @text-color-secondary;
    }
    &.ant-tabs-tab-active,
    &.ant-tabs-tab-active .tab-item{
      color: @text-color;
    }
  }
}
.ant-form-item-has-error .ant-input:focus, .ant-form-item-has-error .ant-input-affix-wrapper:focus, .ant-form-item-has-error .ant-input-focused, .ant-form-item-has-error .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.ant-drawer-title{
  font-weight: bold;
}
.ant-image-img{
  object-fit: cover;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 116px;
}
.ant-modal-content-custom {
  .ant-modal-body{
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 18px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    button {
      width: 130px;
      height: 40px;
    }
  }
}
