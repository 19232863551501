@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.h-withdrawal {
  width: 840px;
  height: 100%;
  padding: 14px;
  display: flex;
  .h-w-list {
    width: 280px;
    height: 100%;
    background: #ffffff;
    border-radius: 16px 0px 0px 16px;
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    .h-w-t {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 59px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      border-bottom: 1px solid @border-color-base;
      margin-bottom: 0;
    }
    .order-list {
      border-radius: 0 0 0 16px;
      background-color: #fff;
      height: calc(100vh - 142px);
      overflow: auto;
      .item {
        cursor: pointer;
        padding: 14px;
        border-bottom: 1px solid @border-color-base;
        transition: all 300ms;
        .orderno {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 4px;
        }
        .item-bottom {
          .df();
          justify-content: space-between;
          .date {
            color: @text-color-secondary;
          }
          .price {
            color: @text-color;
          }
        }
        &.selected {
          background-color: @primary-color;
          * {
            color: #fff !important;
          }
        }
      }
    }
  }
  .h-w-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .d-car-list {
      border-radius: 0px 16px 16px 0px;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .l-box {
        background: #ffffff;
        overflow: hidden;
        overflow-y: auto;
        height: calc(100vh - 300px);
      }
      .l-sum {
        height: 54px;
        background: rgba(242, 243, 246, 0.4);
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
        border-top: 1px solid @border-color-base;
        &-t {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        &-p {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #000000;
        }
      }
      .l-settl {
        height: 58px;
        background: #ffffff;
        border-radius: 0px 0px 16px 16px;
        border-top: 1px solid @border-color-base;
        display: flex;
        .del {
          flex: 1;
          padding: 0 14px;
          display: flex;
          align-items: center;
        }
        .add {
          width: 120px;
          height: 58px;
          background: #fcb75d;
          color: #fff;
          line-height: 58px;
          text-align: center;
          cursor: pointer;
        }
        .settl {
          width: 140px;
          height: 58px;
          background: #86CE65;
          border-radius: 0px 0px 16px 0px;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .no-data-con {
    height: 100%;
    padding-top: 150px;
    background-color: #fff;
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;