@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.appoint-create-con {
  width: 900px;
  height: 100%;
  .df();
  flex-direction: column;
  justify-content: space-between;

  .form,
  .block-con {
    width: 100%;
  }

  .form-checkbox {
    .ant-form-item {
      flex: 1;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  .cell-con {
    width: 100%;
    margin-top: 8px;
    background-color: #fff;
    padding: 0 14px;

    .cell-title {
      position: sticky;
      top: 0;
      background-color: #fff;
      width: 100%;
      z-index: 199;
      padding: 16px 0;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px solid @border-color-base;
    }

    .search-con {
      padding: 14px 0 5px;

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-select-clear {
        background-color: transparent;
      }

      .ant-input-affix-wrapper,
      .ant-input {
        background-color: #f2f3f6;
      }

      .ant-input-group-addon:before {
        background-color: #e5e7e6;
      }
    }
  }

  .form-group {
    &:not(:last-child) {
      border-bottom: 1px solid @border-color-base;
    }

    .form-line {
      .df();
      flex-wrap: wrap;

      &:not(:last-child) {
        border-bottom: 1px solid @border-color-base;
      }

      .ant-form-item {
        width: 50%;
        padding: 16px 15px;
        margin-bottom: 0;
        position: relative;

        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: 30px;
            width: 1px;
            background-color: @border-color-base;
          }
        }

        .ant-form-item-label {
          text-align: left;
        }

        .ant-form-item-explain {
          margin-top: 5px;
        }
      }

      &.oneline {
        .ant-form-item {
          width: 100%;

          .ant-input {
            resize: none;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .form-serve {
    flex: 1;

    .ant-row {
      display: flex;
      width: 100% !important;

      .ant-select {
        width: 200px;
      }
    }
  }

  .order-info {
    line-height: 46px;
    padding: 0 14px;
    font-weight: bold;
    background-color: #f2f3f6;
    .df();
    justify-content: space-between;

    .l {
      & > span {
        margin-right: 15px;
        font-size: 16px;
      }
    }

    .r {
      .price {
        font-size: 24px;
        margin-left: 5px;
      }
    }
  }

  .handle-con {
    height: 58px;
    .df();
    background-color: #fff;
    justify-content: center;

    .submit {
      width: 320px;
      display: block;
      margin: 0 auto;
    }

    .classCart {
      margin-left: 20px;
    }
  }

  .block-con {
    position: sticky;
    bottom: 0;
  }
  .form-checkbox {
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
    }
  }

  .ant-picker-cell-inner {
    position: relative;

    .full {
      position: absolute;
      top: -10px;
      right: -10px;
      color: @error-color;
    }
  }

  .addForm-serve {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid @border-color-base;
    .ant-row {
      flex: 1;
    }

    .addForm-serve-radius {
      border-radius: 50%;
      border: 1px solid #ccc;
    }

    .addform-space-spu {
      width: 48%;
      align-items: center;
      display: flex;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    .addform-space-sku {
      width: 48%;
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
    }

    .addform-space-last {
      width: 100%;
      .ant-row {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .addform-title-row {
      display: flex;
      margin-bottom: 10px;
    }
  }
  .member-address-input-con {
    width: 310px;
  }
  .del-con {
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    .del-icon {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;