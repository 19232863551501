@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.m-detail {
  height: 100%;
  overflow: hidden;
  position: relative;
  .title {
    height: 65px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @border-color-base;
    .t {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
    }
    .icon {
      svg {
        cursor: pointer;
        width: 18px;
        height: 18px;
        background: #bdc8d3;
        border-radius: 9px;
      }
    }
  }
  .main {
    height: calc(100% - 108px);
    .user {
      padding: 0 14px;
      height: 104px;
      line-height: 1;
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @border-color-base;
      &-avatar {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin-left: 14px;
      }
      .info {
        flex: 1;
        margin-left: 14px;
        .info_t {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.1;
        }
        .info_p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #767b84;
          .level {
            color: #86CE65;
          }
        }
      }
    }
    .deinfo {
      padding: 0 14px;
      padding: 16px 14px;
      .col {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #767b84;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-of-type(even) {
          text-align: right;
          color: #000000;
        }
      }
      .btn {
        margin-top: 6px;
        width: 100%;
      }
    }
    .bod {
      margin: 0 14px;
      border-bottom: 1px solid @border-color-base;
    }
    .history {
      margin: 16px 0;
      height: calc(100% - 286px);
      .infinite-scroll-con {
        padding: 0 14px;
      }
      .t {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
        margin-bottom: 14px;
        padding: 0 14px;
      }
      .list {
        overflow: hidden;
        overflow-y: auto;
        height: calc(100% - 20px);
        .item {
          margin-bottom: 14px;
          height: 68px;
          background: #ffffff;
          border-radius: 12px;
          border: 1px solid #e5e7e6;
          display: flex;
          overflow: hidden;
          cursor: pointer;
          .pet-img {
            width: 68px;
            height: 68px;
            border-radius: 12px 0px 0px 12px;
            object-fit: cover;
          }
          .item-r {
            flex: 1;
            padding: 12px;
            overflow: hidden;
            .supTitle {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 0;
            }
            .r_info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .price {
                font-weight: bold;
                font-size: 12px;
              }
              .stock {
                font-size: 12px;
                color: #767b84;
                &.minus {
                  color: #ffa725;
                }
              }
              .stock-add-btn {
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 58px;
    background: #ffffff;
    box-shadow: 0px -4px 8px 0px rgba(25, 34, 46, 0.08);
    position: absolute;
    left: 0;
    bottom: 0;
    .col {
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: '';
        width: 1px;
        height: 36px;
        background: @border-color-base;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
      .btn {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8393a2;
      }
    }
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;