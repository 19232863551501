@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.appoint-detail {
  height: calc(100vh - 94px);
  display: flex;
  flex-flow: column;
  .order-info {
    width: 100%;
    min-height: 136px;
    background: #ffffff;
    margin-bottom: 8px;
    .order-title {
      display: flex;
      justify-content: space-between;
      height: 48px;
      align-items: center;
      padding: 0 14px;
      border-bottom: 1px solid @border-color-base;
      .orderNo {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #19222e;
      }
      .orderTime {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #19222e;
        display: flex;
        align-items: center;
      }
    }
    .o-info {
      position: relative;
      .o-info-box {
        .row-1 {
          padding: 0 33px;
        }
        .labelW {
          min-width: 80px;
        }
        .petImg {
          width: 70px;
          height: 70px;
          border-radius: 6px;
        }
      }
      .s-icon {
        margin-right: 10px;
        svg {
          font-size: 16px;
        }
      }
      .o-i-status {
        padding: 8px 14px;
        border-radius: 21px 0px 0px 21px;
        position: absolute;
        top: 7px;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        &.s-100 {
          background: #ffa725;
        }
        &.s-200 {
          background: #86CE65;
        }
        &.s-600 {
          background: #fc5553;
        }
        &.s-500,
        &.s-700,
        &.s-800,
        &.s-900 {
          background: #bdc8d3;
        }
      }
    }
  }
  .appoint-info {
    width: 100%;
    min-height: 160px;
    background: #ffffff;
    margin-bottom: 8px;
    padding-bottom: 14px;
    .a-i-title {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      padding-left: 15px;
      position: relative;
      margin: 14px -15px 14px 15px;
      &::after {
        content: '';
        width: 4px;
        height: 20px;
        background: #86CE65;
        border-radius: 2px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .a-i-box {
      padding: 0 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 105px;
      .user-info {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #e5e7e6;
        margin-right: 13px;
        overflow: hidden;
        position: relative;
        .title {
          width: 102px;
          height: 26px;
          border-radius: 0px 0px 0px 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          position: absolute;
          bottom: 0;
          left: -2px;
          z-index: 1;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -2px;
            width: 100%;
            height: 100%;
            transform: skewX(25deg);
            background: #ffa725;
            z-index: -1;
          }
        }
        .user {
          padding: 0 18px;
          display: flex;
          margin-bottom: 4px;
          margin-top: 12px;
          .ant-image {
            display: flex;
            align-items: center;
          }
          .ava {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin-right: 18px;
          }
          .u-info {
            flex: 1;
            .u-i-1 {
              margin-bottom: 6px;
              &.flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              &.flex1 {
                display: flex;
                align-items: center;
              }
              .u-i-t {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #000000;
                margin-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .u-i-v {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #86CE65;
                background: rgba(0, 194, 198, 0.15);
                border-radius: 16px;
                padding: 0 12px;
                min-width: 80px;
                text-align: center;
                height: 26px;
                line-height: 26px;
                display: inline-block;
              }
              .u-i-p {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #767b84;
              }
              .u-i-balance {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #1f2429;
                .b {
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #1f2429;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .pet-info {
        flex: 1;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #e5e7e6;
        overflow: hidden;
        position: relative;
        .title {
          width: 88px;
          height: 26px;
          border-radius: 0px 0px 0px 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          position: absolute;
          left: -2px;
          bottom: 0;
          z-index: 1;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -2px;
            width: 100%;
            height: 100%;
            transform: skewX(25deg);
            background: #86CE65;
            z-index: -1;
          }
        }
        .user {
          padding: 0 18px;
          display: flex;
          // margin-top: 12px;
          // margin-bottom: 8px;
          .ant-image {
            display: flex;
            align-items: center;
          }
          .ava {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin-right: 18px;
          }
          .p-info {
            margin-top: 10px;
            flex: 1;
            .row-1 {
              .col-1,
              .col-2 {
                width: 45%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .col-weight {
          position: absolute;
        }
      }
    }
  }
  .order-detail {
    flex: 1;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
    .a-i-title {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      padding-left: 15px;
      position: relative;
      margin: 14px -15px 14px 15px;
      &::after {
        content: '';
        width: 4px;
        height: 20px;
        background: #86CE65;
        border-radius: 2px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .o-d-box {
      .ant-table-wrapper {
        padding: 0 23px;
      }
    }
    .o-d-box,
    .o-info-box {
      .row-1 {
        padding: 0 33px;
      }
      .col-1,
      .col-2 {
        .t {
          &.labelW {
            min-width: 80px;
            margin-right: 20px;
          }
        }
        .i {
          word-break: break-all;
          max-width: 600px;
        }
      }
      .labelW {
        min-width: 80px;
      }
      .petImg {
        width: 70px;
        height: 70px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
  .pay-info-plac {
    height: 80px;
    background-color: #fff;
  }
  .pay-info {
    width: 100%;
    height: 50px;
    background: #f2f3f6;
    border-radius: 1px;
    padding: 0 18px;
    font-size: 16px;
    .df();
    justify-content: space-between;
    .l {
      .df();
    }
    .item {
      margin-right: 30px;
    }
    .r {
      .order_price {
        margin-right: 20px;
      }
    }
  }
  .pay-action {
    width: 100%;
    height: 58px;
    background: #ffffff;
    border-radius: 0px 0px 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
  }

  .row-1 {
    // padding: 0 33px;
    &.flex-start {
      .col-1,
      .col-2 {
        align-content: flex-start;
        align-items: inherit;
      }
    }
    .col-1,
    .col-2 {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      &.pet {
        margin-bottom: 6px;
      }
      .t {
        margin-right: 40px;
        font-size: 14px;
        min-width: 60px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #767b84;
      }
      .i {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #19222e;
      }

      .select {
        width: 252px;
      }
    }
    .col-2 {
      padding-left: 10px;
    }
  }
  .ant-btn {
    width: 130px;
    height: 40px;
    border-radius: 21px;
    &.ant-btn-background-ghost.warning {
      border: 1px solid #ffa725;
      color: #ffa725;
    }
  }
  .price {
    color: #86CE65;
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;