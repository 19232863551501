@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.messager-list {
  width: 500px;
  background-color: #fff;
  .order-list {
    height: calc(100vh - 120px);
    padding: 0 14px;
    overflow: auto;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
    padding: 0 14px;
  }
  .ant-tabs-tab {
    padding: 18px 0;
    font-weight: bold;
    margin-right: 30px;
    .tab-item {
      padding: 0 18px;
    }
  }
  .message-item {
    padding: 14px 16px 14px 24px;
    border-bottom: 1px solid @border-color-base;
    position: relative;
    .df();
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    &.off {
      &:before {
        content: '';
        position: absolute;
        top: 28px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: @primary-color;
      }
    }
    &.link {
      &:after {
        content: '';
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        border: solid #959ba2;
        border-width: 1px 1px 0 0;
        width: 8px;
        height: 8px;
      }
    }
    .icon {
      font-size: 38px;
      margin-right: 14px;
    }
    .content {
      flex: 1;
      .tag {
        padding: 1px 5px;
        border-radius: 2px;
        font-size: 11px;
        &.off {
          color: @primary-color;
          background-color: #d9f6f7;
        }
        &.on {
          color: #f78100;
          background-color: #feecd9;
        }
      }
      h3 {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
      }
      .bottom {
        .df();
        justify-content: space-between;
        color: @text-color-secondary;
      }
    }
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;