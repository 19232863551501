@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.pet-detail-container {
  margin-bottom: 14px;
  .ant-collapse-header {
    display: block !important;
    width: 100%;
  }
  .p-header {
    height: 140px;
    &.active {
      .p-h-t {
        .p-h-t1 {
          color: #86CE65;
        }
      }
    }
    .p-h-t {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 40px;
      border-bottom: 1px solid #e5e7e6;
      .p-h-t1 {
        font-size: 14px;
        font-weight: 400;
        color: #767b84;
      }
    }
    .p-h-content {
      height: 104px;
      display: flex;
    }
    .pet-id {
      width: 252px;
      height: 36px;
      border-radius: 8px 0 0 0;
      background-color: @primary-color;
      color: #fff;
      position: relative;
      padding: 8px 14px;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-width: 0 0 38px 20px;
        border-style: solid;
        border-color: transparent transparent white;
      }
    }
  }
  .c-l {
    width: 30%;
    padding: 14px;
    display: flex;
    .head-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .c-l-t {
      & > h2 {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }
      & > p {
        font-size: 14px;
        font-weight: 400;
        color: #767b84;
      }
    }
  }
  .c-r {
    flex: 1;
    padding: 14px 0;
    .c-r-row {
      display: flex;
      &.memo {
        .c-r-col-1 {
          .label {
            width: 56px;
            display: flex;
            justify-content: space-between;
          }
          .value {
            padding-right: 14px;
            flex: 1;
            word-break: break-all;
          }
        }
      }
      & + .c-r-row {
        margin-top: 8px;
      }
      .c-r-col-3 {
        width: 33.33%;
      }
      .c-r-col-1 {
        width: 100%;
      }
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      color: #767b84;
      & + .value {
        margin-left: 23px;
      }
    }
    .value {
      font-size: 14px;
      font-weight: 400;
      color: #19222e;
    }
  }
  .p-content {
    .pet-imgs {
      min-height: 98px;
      display: flex;
      border-bottom: 1px dashed #e5e7e6;
      .pet-image {
        width: 70px;
        height: 70px;
        background: #d8d8d8;
        border-radius: 4px;
        &:last-child {
          margin-right: 0;
        }
      }
      .ant-image {
        margin-right: 10px;
      }
    }
    .pet-memo {
      min-height: 48px;
      display: flex;
      border-bottom: 1px dashed #e5e7e6;
    }
    .pet-edit-btns {
      height: 33px;
      padding: 0 10px;
      line-height: 33px;
      text-align: right;
      .b-edit {
        color: #86CE65;
      }
    }
  }
  .ant-collapse {
    border-radius: 8px;
    background-color: #fff;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 8px 8px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 8px 8px;
    border-top: 1px dashed #e5e7e6;
  }
  .ant-collapse-content {
    background-color: transparent;
  }
  .ant-collapse-icon-position-right {
    & > .ant-collapse-item {
      & > .ant-collapse-header .ant-collapse-arrow {
        top: 15px;
        right: 16px;
        padding: 0;
        color: #86CE65;
        svg {
          transform: rotate(90deg);
        }
      }
      &.ant-collapse-item-active {
        & > .ant-collapse-header .ant-collapse-arrow {
          svg {
            transform: rotate(-90deg) !important;
          }
          // color: #86CE65;
          color: #767b84;
        }
      }
    }
    & > .ant-collapse-item > .ant-collapse-header {
      background-color: transparent;
      padding: 0;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;