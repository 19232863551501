@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.a-calendar {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .a-nav {
    height: 62px;
    background: #ffffff;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfe0e3;
    .nav-item {
      // width: 33.33%;
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .adjust-btn {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
    }
    .search-btn {
      width: 334px;
      height: 36px;
      border-radius: 18px;
    }
    .time-lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .item {
        width: 96px;
        min-width: 96px;
        height: 22px;
        border-right: 1px solid #e5e7e6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #595959;
        &:last-child {
          border-right: 0;
          width: 140px;
        }
        .ant-picker-input {
          & > input {
            &::-webkit-input-placeholder {
              font-size: 16px;
            }
          }
        }
        &.active {
          color: #86CE65;
          .ant-picker-input > input {
            color: #86CE65;
            font-size: 16px;
          }
          .icon {
            svg {
              path {
                fill: #86CE65;
              }
            }
          }
        }
      }
    }
  }
  .a-content-box {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    height: 100%;
  }
  .date-list-bg {
    width: 100%;
    height: 50px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .a-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
    position: absolute;
    top: 0;
    left: 0;
    .a-c-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .date-list {
      width: 100%;
      height: 50px;
      background: #ffffff;
      z-index: 2;
      &::after {
        content: '';
        display: block;
        clear: both;
      }
      .item {
        float: left;
        width: 240px;
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: rgba(149, 155, 162, 1);
        .time {
          color: #000;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 30px;
          background: #e5e7e6;
          border-radius: 16px;
        }
      }
    }
  }
  .action {
    width: 48px;
    height: 49px;
    background: #ffffff;
    box-shadow: -3px 0px 4px 0px rgba(25, 34, 46, 0.08);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
  }
  .a-c-list {
    padding-top: 0.778rem;
    display: flex;
    overflow: hidden;
    height: 100%;
    .c-date-item {
      width: 240px;
      overflow: hidden;
      overflow-y: auto;
    }
    .ant-empty {
      width: 100%;
    }
  }
  .DetailAndCash {
    width: 970px;
  }
}
.ghost-button {
  width: 250px !important;
}

@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;