@primary-color: #86CE65; // 全局主色
@link-color: #86CE65; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #FF990E; // 警告色
@error-color: #F46362; // 错误色
@font-size-base: 14px; // 主字号
@heading-color: #262626; // 标题色
@text-color: #262626; // 主文本色
@text-color-secondary: #595959; // 次文本色
@disabled-color: #BFBFBF; // 失效色
@border-radius-base: 0; // 组件/浮层圆角
@border-radius-secondary: 0; // 次 组件/浮层圆角
@border-color-base: #B4B5AA; // 边框色
@box-shadow-base: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); // 浮层阴影
@bg-color: #F4F4F4;
p {
  margin: 0;
}
.df() {
  display: flex;
  align-items: center;
}
.__df {
  .df()
}
.error-color{
  color: @error-color;
}
.primary-color {
  color: @primary-color !important;
}
// 滚动条
* {
  &::-webkit-scrollbar {
    width: 6px;
    background: #EAECF0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #959BA2;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    height: 3px;
  }
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pay-type-con {
  .df();
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  border: solid #e5e7e6;
  border-width: 1px 0;
  .comb-con {
    width: 129px;
    min-width: 129px;
    text-align: center;
  }
  .pay-type-title-list {
    width: 70px;
    margin-left: 14px;
    margin-right: 22px;
    .title {
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      .comb-con {
        position: absolute;
        right: -130px;
      }
    }
    & > p,
    .title {
      height: 46px;
      line-height: 46px;
    }
    & > p {
      margin-top: 10px;
    }
  }
  .pay-type {
    flex: 1;
    &.is-comb {
      .pay-item-con:first-child {
        position: relative;
        margin-right: 31px;
        &:after {
          content: '+';
          position: absolute;
          right: -22px;
          top: 45%;
          font-weight: bold;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
      .pay-item-con {
        &.pay-item-con-WXCASH {
          margin-left: 156px;
        }
      }
    }
    .pay-item-con {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 125px;
      height: 46px;
      margin-right: 12px;
      .pay-item {
        width: 130px;
        height: 46px;
        line-height: 46px;
        .df();
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #e5e7e6;
        .icon {
          font-size: 30px;
          margin-right: 8px;
        }
        &.selected {
          background: rgba(134, 206, 101, 0.1);
          border: 1px solid #86CE65;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: #7f7f7f;
        .pay-item {
          cursor: not-allowed;
        }
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }
}

.logistics-detail-con{
  width: 756px;
  height: 100%;
  padding: 14px;
  .logistics-detail{
    background-color: #fff;
    height: 100%;
    border-radius: 16px;
    padding: 10px 26px 30px 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .section{
      .section-title{
        font-size: 14px;
        font-weight: bold;
        margin-top: 14px;
      }
      .info-con{
        display: flex;
        flex-wrap: wrap;
        .info-item{
          display: flex;
          align-items: flex-start;
          width: 50%;
          margin-top: 14px;
          color: #19222E;
          .info-title{
            width: 96px;
            min-width: 96px;
            color: #767B84;
          }
          &.oneline{
            width: 100%;
          }
        }
      }
    }
    .log-con{
      margin-top: 22px;
      background-color: #F5F7F9;
      border-radius: 4px;
      padding: 8px 22px 24px;
      color: #919AA7;
      flex: 1;
      overflow: auto;
      background-image: url('../../assets/member/history/che.png');
      background-size: 161px 111px;
      background-position: right bottom;
      background-repeat: no-repeat;
      .log-item{
        margin-top: 16px;
        display: flex;
        position: relative;
        &:not(:last-child) {
          &:before {
            content: '';
            position: absolute;
            width: 1px;
            border: dashed #979797;
            border-width: 0 1px 0 0;
            left: 59px;
            top: 18px;
            bottom: -25px;
            z-index: 9;
          }
        }
        .log-status {
          width: 42px;
          min-width: 42px;
          font-size: 14px;
        }
        .icon {
          width: 8px;
          min-width: 8px;
          height: 8px;
          min-height: 8px;
          background: #919AA7;
          border-radius: 50%;
          margin: 7px 14px 0;
          position: relative;
          z-index: 19;
          &.complete{
            padding: 0;
            width: 16px;
            height: 16px;
            margin: 3px 10px 0;
            background-image: url('../../assets/common/gouxuan.png');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .content{
          margin-left: 18px;
          width: 274px;
        }
      }
    }
  }
}
@primary-color: #86CE65;@link-color: #86CE65;@border-radius-base: 0;